var generic = generic || {};

(function ($) {
  Drupal.behaviors.stickyFooterOffer = {
    attach: function (context) {
      // allow modules to add body class to supress sticky offer on certain
      // pages.
      if ($('body').hasClass('suppress-sticky-offer')) {
        return;
      }
      var $offer = $('.sticky-offer', context);

      function animateTodaysOffer() {
        var firstTime = !$.cookie('persistent_user_cookie');

        if (firstTime) {
          $.cookie('persistent_user_cookie', 1, {
            expires: 365,
            path: '/',
            secure: true,
            SameSite: 'Lax'
          });
          var closeTimeout;
          var bounceTimeout;
          var openTimeout = setTimeout(function () {
            $offer.addClass('expanded');
            bounceTimeout = setTimeout(function () {
              $offer.addClass('bounce');
              closeTimeout = setTimeout(function () {
                $offer.removeClass('expanded').off('mouseover.bbOffer');
              }, 7000);
            }, 10);
          }, 500);

          $offer.once().on('mouseover.bbOffer', function () {
            clearTimeout(openTimeout);
            clearTimeout(bounceTimeout);
            clearTimeout(closeTimeout);
            $(this).off('mouseover.bbOffer').removeClass('expanded bounce');
          });
        }
      }

      // Set the top position of the sticky chat/offer elements. The default css
      // only accounts for 2 lines of copy.
      function _setTop() {
        var currentSession = generic.cookie('currentSession');
        var firstTime = generic.cookie('firstTime');
        var expirationDate = new Date();

        expirationDate.setTime(expirationDate.getTime() + (30 * 60 * 1000));
        if (currentSession === undefined) {
          var timestamp = $.now();

          generic.cookie('currentSession', timestamp, { path: '/' });
          currentSession = timestamp;
        }
        $('.sticky-chat', context).add($offer).each(function () {
          var that = $(this);
          var height = $('.sticky-chat').css('height');

          that.height(that.outerHeight(false));
          // we need to set the initial bottom out of the viewpoint, so we can set the display block
          // without it the element height resulted in outerHeight is not the correct value until then
          that.css({
            bottom: -500,
            display: 'block'
          });
          var initialHeight = 35 - that.outerHeight(false);

          setTimeout(function () {
            that.css('bottom', initialHeight);
          }.bind(this), 50);
          if (that.hasClass('sticky-offer') && firstTime !== currentSession) {
            generic.cookie('firstTime', currentSession, {
              path: '/',
              expires: expirationDate.toGMTString()
            });
            setTimeout(function (initialBottom) {
              var $element = $('.sticky-offer');

              $element.css('bottom', 0);
              $element.css('height', 'auto');
              setTimeout(function ($element, initialBottom) {
                $element.css('bottom', initialBottom);
                $element.css('height', height);
              }, 4000, $element, initialBottom);
            }, 4000, initialHeight);
          }
        });
      }

      // Call it again after we know the fonts are loaded:
      if (!this.attached) {
        $(document).ready(function () {
          _setTop();
          $offer.on('touchstart', function (evt) {
            $(this).css('bottom', 0);
            $(this).css('height', 'auto');
            setTimeout(function () {
              $offer.find('a').eq(0).click();
            }, 500);
            if (!this.delayed) {
              this.delayed = true;
              evt.preventDefault();
            }
          });
          $offer.hover(
            function () {
              $(this).css('height', 'auto');
            },
            function () {
              var height = $('.sticky-chat').css('height');

              $(this).css('height', height);
            }
          );
        }).on('resize-debounced', _setTop);
      }
      this.attached = true;
    },
    attached: false,
    delayed: false
  };

  // LiveChat v3 CTA
  if (typeof lpMTagConfig !== 'undefined') {
    $('.js-open-lc-pre_chat').once('js-open-lc-pre_chat').click(function () {
      lpMTagConfig.delegate.prechatWindow($);
    });
  }
})(jQuery);
